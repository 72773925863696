import { sortInOrder } from '../../../util'

const DURATIONS = {
  NA: '(one-way)',
  SAME: 'same day',
  ONE_TWO: '1-2 days',
  THREE_SEVEN: '3-7 days',
  EIGHT_THIRTYONE: '8-31 days',
  THIRTYTWO_HUNDRED: '32-100 days',
  OVER_HUNDRED: '>100 days',
  UNKNOWN: 'Unknown',
}

export function byDuration(leg, tripMode) {
  if (!tripMode) {
    return 'n.a.'
  }

  if (!leg.roundtrip) {
    return DURATIONS.NA
  }

  const { duration } = leg

  if (duration == null) {
    return DURATIONS.UNKNOWN
  }

  if (duration === 0) {
    return DURATIONS.SAME
  }

  if (duration <= 2) {
    return DURATIONS.ONE_TWO
  }

  if (duration <= 7) {
    return DURATIONS.THREE_SEVEN
  }

  if (duration <= 31) {
    return DURATIONS.EIGHT_THIRTYONE
  }

  if (duration <= 100) {
    return DURATIONS.THIRTYTWO_HUNDRED
  }

  return DURATIONS.OVER_HUNDRED
}

export function sortDuration(a, b) {
  const order = [
    DURATIONS.NA,
    DURATIONS.SAME,
    DURATIONS.ONE_TWO,
    DURATIONS.THREE_SEVEN,
    DURATIONS.EIGHT_THIRTYONE,
    DURATIONS.THIRTYTWO_HUNDRED,
    DURATIONS.OVER_HUNDRED,
    DURATIONS.UNKNOWN,
  ]

  return sortInOrder(a, b, order)
}
