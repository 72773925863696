import { Flight } from '../../data/types'

function getItemYear(flight: Flight) {
  return new Date(flight.leg_date).getFullYear().toString()
}

function getSumOfDistance(flights: Flight[]) {
  return flights.reduce((acc, curr) => acc + curr.km, 0)
}

function getOrgCode(flight: Flight) {
  return flight?.['accountable org code']?.[0]
}

function getCO2(flight: Flight) {
  return flight['CO2e RFI2 (t)'] + 0.152 * flight['CO2e (t)']
}

function getCO2Unit(perFte: boolean) {
  return perFte ? 'kg' : 't'
}

function getSumOfCO2(flights: Flight[]) {
  return flights.reduce((acc, curr) => {
    return acc + getCO2(curr)
  }, 0)
}

export { getItemYear, getOrgCode, getSumOfDistance, getSumOfCO2, getCO2Unit }
