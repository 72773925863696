import format from 'date-fns/format'

export function byStartDay(leg) {
  const { leg_date, leg_date_unknown } = leg

  // leg_date_unknown only in legs, not trips
  if (!leg_date || leg_date_unknown) {
    return 'Unknown'
  }

  return format(new Date(leg_date), 'EEE')
}
