/*
 * This collects all buckets of categorized things.
 * It uses references during the collection in order to avoid frequent re-rendering.
 * After collection is done, finalize() is called.
 */
import { useState, useRef, useCallback } from 'react'

export default function useBucketsByGroup() {
  const [buckets, setBuckets] = useState({})

  const _buckets = useRef({})

  const resetBuckets = useCallback(() => {
    _buckets.current = {}
  }, [])

  /**
   * Add value to bucket
   * @param {string} groupKey - The bucket name to add to
   * @param {string} name - The name of an item in the bucket
   * @param {number} value - The amount to add to a bucket
   */
  const addBucket = useCallback((groupKey, name, value) => {
    if (!(groupKey in _buckets.current)) {
      _buckets.current[groupKey] = {}
    }
    if (!(name in _buckets.current[groupKey])) {
      _buckets.current[groupKey][name] = 0
    }
    _buckets.current[groupKey][name] += value

    return name
  }, [])

  /**
   * Reduce value from a bucket
   * @param {string} groupKey - The bucket name to reduce from
   * @param {string} name - The name of an item in the bucket
   * @param {number} value - The amount to reduce from a bucket
   */
  const reduceBucket = useCallback((groupKey, name, value) => {
    if (!(groupKey in _buckets.current)) {
      return
    }
    if (name in _buckets.current[groupKey]) {
      _buckets.current[groupKey][name] = Math.max(
        0,
        _buckets.current[groupKey][name] - value
      )
    }
  }, [])

  const finalizeBuckets = useCallback(() => {
    setBuckets(_buckets.current)
  }, [])

  return {
    buckets,
    resetBuckets,
    addBucket,
    finalizeBuckets,
    reduceBucket,
  }
}
