import { useCallback, useMemo, useEffect } from 'react'
import { rollups } from 'd3-array'
import { hierarchy } from 'd3-hierarchy'
import { isWithinInterval } from 'date-fns'

import { byOrgCode } from './filters/orgCode'
import { byStartDay } from './filters/startDay'
import { byBookingClass } from './filters/bookingClass'
import { byDuration } from './filters/duration'
import { byGender } from './filters/gender'
import { byManager } from './filters/manager'
import { byAge } from './filters/age'
import { useHaul } from './filters/useHaul'
import { useTime } from './filters/useTime'
import { useLocation } from './filters/useLocation'
import useBucketsByFilterName from './useBucketsByFilterName'
import {
  useIndicatorTripMode,
  getFlightTrips,
} from '../indicatorTripModeContext'
import { useFilter } from '../filterContext'
import useIndicators from '../indicatorTripModeContext/useIndicators'
import { useAggregateData } from '../aggregateDataContext'
import { useDate } from '../dateContext'
import { useActiveFilterIndices } from '../activeFilterIndices'
import { usePerFte } from '../perFteContext'
import useGetKeyFromDepth from './useGetKeyFromDepth'
import useData from '../../../src/data/useData'
import { getOrgCode } from '../indicatorTripModeContext/utils'

/**
 * @typedef {Object} Node
 * @property {string} name - Name of node.
 * @property {Node[]} [children] - Child nodes of node.
 * @property {number} [size] - Size of node, if no children.
 */

export function resolveChildren([name, childrenOrSize]) {
  if (Array.isArray(childrenOrSize)) {
    return { name, children: childrenOrSize.map(resolveChildren) }
  } else {
    return { name, size: childrenOrSize }
  }
}

function getSumOfNodeSizes(node) {
  return node.sum((d) => d.size).value
}

export function getFilteredData(flights, date, perFte) {
  const filteredData = flights?.filter((d) => {
    // We don't count items without org in per-FTE because a per-FTE count cannot be defined for an unknown org unit.
    // In future, we might decide to count them if the per-FTE setting is _all_ (all institution).
    if (perFte && !getOrgCode(d)) {
      return false
    }

    // TODO: Remove this conditional check when dateContext is properly tested
    if (date.min && date.max && date.min < date.max) {
      return isWithinInterval(new Date(d.leg_date), {
        start: date.min,
        end: date.max,
      })
    }

    return true
  })

  return filteredData
}

export default function useDataFilters(filterDefinitions, isOrgInvalid) {
  const { flightLegs, airports, orgCodes } = useData()
  const { tripMode } = useIndicatorTripMode()
  const { perFte } = usePerFte()
  const { selectedIndicator } = useIndicators(perFte)
  const { date } = useDate()

  const [, setAggregateData] = useAggregateData()
  const { byHaul } = useHaul()
  const { byTime } = useTime()
  const { byLocation } = useLocation()
  const { filteredList } = useFilter()

  const {
    buckets,
    resetBuckets,
    addBucket,
    finalizeBuckets,
    reduceBucket,
  } = useBucketsByFilterName()

  // Dates to filter
  // const [filterDates, setFilterDates] = useState({ start: 0, end: 0 })
  // Grouping data. (note the other api: set, reset, etc.)
  const { activeFilterIndices } = useActiveFilterIndices()

  const addBucketDefs = useCallback(
    (key) => {
      const transformedDefs = {
        haul: (d) => byHaul(d),
        time: (d) => byTime(d.leg_date),
        startDay: (d) => byStartDay(d),
        duration: (d) => byDuration(d, tripMode),
        cls: (d) => byBookingClass(d.class),
        location: (d) => byLocation(d.from, d.to),
        org_code: (d) => (isOrgInvalid ? '' : byOrgCode(orgCodes, d)),
        gender: (d) => byGender(d),
        manager: (d) => byManager(d),
        age: (d) => byAge(d),
      }

      const genericDef = (d) => d[key] || 'Unknown'

      return transformedDefs[key] ?? genericDef
    },
    [tripMode, byHaul, byLocation, byTime, orgCodes, isOrgInvalid]
  )

  /**
   * Get key of the filterDefinition a node belongs to given its depth
   * @param {number} depth - Depth of node in a d3-hierarchy
   */
  const getKeyFromDepth = useGetKeyFromDepth(filterDefinitions)

  /**
   * Add values to bucket, where value will always be an int
   * @param {D3Hierarchy} root - A d3-hierarchy root node
   */
  const addBuckets = useCallback(
    (root) => {
      root.each((node) => {
        const groupKey = getKeyFromDepth(node.depth)
        const value = getSumOfNodeSizes(node)
        addBucket(groupKey, node.data.name, value)
      })
    },
    [addBucket, getKeyFromDepth]
  )

  /**
   * Reduce a node's and its children's bucket values
   * @param {Node} node - The node to reduce bucket value of @param {string[]} groupKeys - List of keys of node and its children
   */
  const reduceBuckets = useCallback(
    (node, groupKeys) => {
      const groupKey = groupKeys[0] // Group key of node
      if (node.children) {
        // Deduct a parent node's bucket value by the sum of its leaf node sizes
        const summedSize = getSumOfNodeSizes(hierarchy(node))
        reduceBucket(groupKey, node.name, summedSize)

        // Adjust bucket values of nodes from the next group key
        node.children.forEach((child) => {
          reduceBuckets(child, groupKeys.slice(1))
        })
      } else {
        reduceBucket(groupKey, node.name, node.size)
      }
    },
    [reduceBucket]
  )

  /**
   * List of badges selected from all filter definitions
   * @type {string[]}
   */
  const activeKeys = useMemo(
    () =>
      activeFilterIndices.map((idx) => {
        return filterDefinitions[idx].key
      }),
    [activeFilterIndices, filterDefinitions]
  )

  /**
   * Get a list of descendant keys i.e. keys lower than and equal to the startKey.
   * @param {string} startKey - The key of the first active filter index
   */
  const getGroupKeys = useCallback(
    (startKey) => activeKeys.slice(activeKeys.indexOf(startKey)),
    [activeKeys]
  )

  /**
   * Adjust bucket values for filter items in filteredList
   * @param {D3Hierarchy} root - A d3-hierarchy root node
   */
  const adjustBucketsForFilters = useCallback(
    (root) => {
      // Traverse the root with BFS
      root.each((d) => {
        const nodeKey = getKeyFromDepth(d.depth)
        // Find matching filtered node - use key to prevent incorrectly matching items with same names in different levels
        const matchindNode = filteredList.find(
          (item) => item.key === nodeKey && item.name === d.data.name
        )
        if (matchindNode) {
          const groupKeys = getGroupKeys(nodeKey)

          // If an ancestor of node had its bucket values reduced don't re-adjust
          // (Because it was already changed when adjusting its ancenstor)
          const isAncestorFiltered = d.ancestors().find((node) => node.reduced)
          if (isAncestorFiltered) {
            return
          }
          // Else mark the node as reduced
          d.reduced = true

          reduceBuckets(d.data, groupKeys)
        }
      })
    },
    [getGroupKeys, getKeyFromDepth, reduceBuckets, filteredList]
  )

  const transformReducedData = useCallback(
    (reducedData) => {
      reducedData.forEach((child) => {
        const root = hierarchy(child)
        addBuckets(root)
      })

      if (filteredList.length) {
        reducedData.forEach((child) => {
          const root = hierarchy(child)
          adjustBucketsForFilters(root)
        })
      }
    },
    [addBuckets, adjustBucketsForFilters, filteredList.length]
  )

  const isOrgBadgeActive = useMemo(() => activeKeys.includes('org_code'), [
    activeKeys,
  ])

  useEffect(() => {
    const flights = tripMode ? getFlightTrips(airports, flightLegs) : flightLegs
    const filteredData = getFilteredData(flights, date, perFte)

    const { name, unit, reducer } = selectedIndicator

    resetBuckets()

    // Here we actually calculate new data.
    let children = []
    if (activeKeys.length) {
      children = Array.from(
        rollups(
          filteredData,
          (v) => reducer(v, isOrgInvalid, isOrgBadgeActive),
          ...activeKeys.map((key) => addBucketDefs(key))
        ),
        resolveChildren
      )

      transformReducedData(children)
    }

    setAggregateData(
      Object.assign(
        { name, unit },
        activeKeys.length
          ? {
              children,
            }
          : { size: reducer(filteredData) }
      )
    )

    finalizeBuckets()
  }, [
    isOrgInvalid,
    perFte,
    finalizeBuckets,
    resetBuckets,
    addBucketDefs,
    tripMode,
    selectedIndicator,
    setAggregateData,
    date,
    transformReducedData,
    airports,
    flightLegs,
    isOrgBadgeActive,
    activeKeys,
  ])

  const dataFilters = useMemo(
    () =>
      filterDefinitions.map((filterDef) => {
        return {
          ...filterDef,
          buckets: buckets?.[filterDef.key],
        }
      }),
    [buckets, filterDefinitions]
  )

  return dataFilters
}
