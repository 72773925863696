import { groups } from 'd3-array'

import {
  getCO2Unit,
  getItemYear,
  getOrgCode,
  getSumOfCO2,
  getSumOfDistance,
} from './utils'
import { YearlyFte, OrgFteByYear, Flight } from './../../data/types'

// Based on some issues with original data being empty, we need a check for NaN.
function throwOnNaN(num) {
  if (isNaN(num)) {
    throw new Error('Valid number expected but found NaN. Check original data.')
  }
  return num
}

const distanceIndicator = {
  name: (tripMode: boolean) =>
    `${tripMode ? 'Flight trip' : 'Flight leg'} distance`,
  unit: 'km',
  reducer: (
    yearlyFte: YearlyFte,
    orgFteByYear: OrgFteByYear,
    perFte: boolean
  ) => (
    items: Flight[],
    isOrgInvalid: boolean,
    isOrgBadgeActive: boolean
  ): number => {
    if (perFte) {
      const itemsByYear = groups(items, getItemYear)

      if (!isOrgInvalid && isOrgBadgeActive) {
        const orgCode = getOrgCode(items[0]) // All items will have the same org code when org badge active
        const totalFteDistanceOrg = itemsByYear.reduce(
          (sum: number, [year, yearItems]) => {
            const sumOfDistance = getSumOfDistance(yearItems)
            const fteForYear = orgFteByYear[year][orgCode]
            const sumForYear = sumOfDistance / fteForYear

            return sum + sumForYear
          },
          0
        )

        return totalFteDistanceOrg
      }

      const totalFteDistance = itemsByYear.reduce(
        (sum: number, [year, yearItems]) => {
          const sumOfDistance = getSumOfDistance(yearItems)
          const sumForYear = sumOfDistance / yearlyFte[year]
          return sum + sumForYear
        },
        0
      )

      return totalFteDistance
    }

    const totalDistance = getSumOfDistance(items)

    return throwOnNaN(totalDistance)
  },
}

export const flightCountIndicator = {
  name: (tripMode: boolean) =>
    `${tripMode ? 'Flight trip' : 'Flight leg'} count`,
  reducer: (
    yearlyFte: YearlyFte,
    orgFteByYear: OrgFteByYear,
    perFte: boolean
  ) => (
    items: Flight[],
    isOrgInvalid: boolean,
    isOrgBadgeActive: boolean
  ): number => {
    if (perFte) {
      const itemsByYear = groups(items, getItemYear)

      if (!isOrgInvalid && isOrgBadgeActive) {
        const orgCode = getOrgCode(items[0])
        const totalFteFlightsOrg = itemsByYear.reduce(
          (sum: number, [year, yearItems]) => {
            const sumOfFlights = yearItems.length
            const fteForYear = orgFteByYear[year][orgCode]
            const sumForYear = sumOfFlights / fteForYear

            return sum + sumForYear
          },
          0
        )

        return totalFteFlightsOrg
      }

      const totalFteFlights = itemsByYear.reduce(
        (sum: number, [year, yearItems]) => {
          const sumForYear = yearItems.length / yearlyFte[year]
          return sum + sumForYear
        },
        0
      )

      return totalFteFlights
    }

    const totalFlights = items.length

    return totalFlights
  },
}

export const co2Indicator = {
  name: () => 'CO2e RFI 2, 15.2%',
  reducer: (
    yearlyFte: YearlyFte,
    orgFteByYear: OrgFteByYear,
    perFte: boolean
  ) => (items: Flight[], isOrgInvalid: boolean, isOrgBadgeActive: boolean) => {
    if (perFte) {
      const itemsByYear = groups(items, getItemYear)

      if (!isOrgInvalid && isOrgBadgeActive) {
        const orgCode = getOrgCode(items[0])
        const totalFteCO2Org = itemsByYear.reduce(
          (sum: number, [year, yearItems]) => {
            const sumOfCO2 = getSumOfCO2(yearItems)
            const fteForYear = orgFteByYear[year][orgCode]
            const sumForYear = sumOfCO2 / fteForYear

            return sum + sumForYear
          },
          0
        )

        return totalFteCO2Org * 1000
      }

      const totalFteCO2 = itemsByYear.reduce((sum, [year, yearItems]) => {
        const sumOfCO2 = getSumOfCO2(yearItems)
        const sumForYear = sumOfCO2 / yearlyFte[year]
        return sum + sumForYear
      }, 0)

      return totalFteCO2 * 1000
    }

    const totalCO2 = getSumOfCO2(items)

    return throwOnNaN(totalCO2)
  },
}

function createIndicators(
  yearlyFte: YearlyFte,
  orgFteByYear: OrgFteByYear,
  tripMode = true,
  perFte = false
) {
  const co2IndicatorWithUnit = {
    ...co2Indicator,
    unit: getCO2Unit(perFte),
  }

  const indicators = [
    co2IndicatorWithUnit,
    flightCountIndicator,
    distanceIndicator,
  ]

  return indicators.map((indicator) => ({
    ...indicator,
    name: indicator.name(tripMode),
    reducer: indicator.reducer(yearlyFte, orgFteByYear, perFte),
  }))
}

export default createIndicators
