const reYearRange = new RegExp(/(\d{4})-(\d{4})/)
const year = new Date().getFullYear()

export function byAge(leg) {
  const yob = leg.yob == null ? 'Unknown' : leg.yob

  const match = yob.match(reYearRange)

  if (!match) {
    return yob
  }

  const [, to, from] = match

  return `${year - from} - ${year - to}y`
}
