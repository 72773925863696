import { useCallback } from 'react'
import { useActiveFilterIndices } from '../activeFilterIndices'

export default function useGetKeyFromDepth(filterDefinitions) {
  const { activeFilterIndices } = useActiveFilterIndices()

  const getKeyFromDepth = useCallback(
    (depth) => filterDefinitions[activeFilterIndices[depth]]?.key,
    [activeFilterIndices, filterDefinitions]
  )

  return getKeyFromDepth
}
