import { sortInOrder } from '../../../util'

const BOOKING_CLASSES = {
  ECONOMY: 'Economy',
  PREMIUM: 'Premium Eco',
  BUSINESS: 'Business',
  FIRST: 'First',
  UNKNOWN: 'Unknown',
}

const classes = {
  Y: BOOKING_CLASSES.ECONOMY,
  P: BOOKING_CLASSES.PREMIUM,
  B: BOOKING_CLASSES.BUSINESS,
  F: BOOKING_CLASSES.FIRST,
}

export function byBookingClass(fareCode) {
  fareCode = fareCode?.toUpperCase()

  return classes[fareCode] ?? BOOKING_CLASSES.UNKNOWN
}

export function sortBookingClass(a, b) {
  const order = [
    BOOKING_CLASSES.ECONOMY,
    BOOKING_CLASSES.PREMIUM,
    BOOKING_CLASSES.BUSINESS,
    BOOKING_CLASSES.FIRST,
    BOOKING_CLASSES.UNKNOWN,
  ]
  return sortInOrder(a, b, order)
}
