const MANAGER = {
  YES: 'yes',
  NO: 'no',
}

export function byManager(leg) {
  const { manager } = leg

  switch (manager) {
    case MANAGER.YES:
      return 'Manager'
    case MANAGER.NO:
      return 'Non-Manager'
    default:
      return manager ?? 'Unknown'
  }
}
