const GENDER = {
  MALE: 'm',
  FEMALE: 'f',
}

export function byGender(leg) {
  const { gender } = leg

  switch (gender) {
    case GENDER.MALE:
      return 'Male'
    case GENDER.FEMALE:
      return 'Female'
    default:
      return gender ?? 'Unknown'
  }
}
