import { useMemo } from 'react'
import { useIndicatorTripMode } from '.'
import { useHelperData } from '../../data/useData'
import createIndicators from './indicators'

export default function useIndicators(perFte) {
  const { yearlyFte, orgFteByYear } = useHelperData()
  const { tripMode, indicatorIndex } = useIndicatorTripMode()

  const indicators = useMemo(
    () => createIndicators(yearlyFte, orgFteByYear, tripMode, perFte),
    [tripMode, perFte, orgFteByYear, yearlyFte]
  )

  const selectedIndicator = useMemo(() => indicators[indicatorIndex], [
    indicators,
    indicatorIndex,
  ])

  return { indicators, selectedIndicator }
}
